import { forwardRef, useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-light-svg-icons'
import { ApplyModal } from '../apply-modal'
import { CaptureModal } from '../captureModal'

import { UnstyledLink } from '../typography/UnstyledLink'
import * as styles from './Button.module.scss'

const Button = forwardRef(
  (
    {
      variant,
      url,
      alt,
      disabled,
      fullWidth,
      className,
      children,
      isModal,
      modalUrlNonMember,
      modalUrlIsMember,
      loading,
      isApply,
      applyHref,
      ...props
    },
    ref
  ) => {
    const [showDialog, setShowDialog] = useState(false)
    const [showApplyDialog, setShowApplyDialog] = useState(false)
    const open = useCallback(() => setShowDialog(true), [])
    const openApply = useCallback(() => setShowApplyDialog(true), [])
    const close = useCallback(() => setShowDialog(false), [])
    const closeApply = useCallback(() => setShowApplyDialog(false), [])
    const classes = classNames(
      styles.Button,
      variant && styles[variant],
      fullWidth && styles.fullWidth,
      loading && styles.loading,
      className
    )

    if (url) {
      return (
        <UnstyledLink
          className={classes}
          to={url}
          alt={alt}
          data-qa="button"
          data-ga={`button-${variant && variant}`}
          ref={ref}
          {...props}
          disabled={disabled}
        >
          {children}
        </UnstyledLink>
      )
    }
    if (isModal) {
      return (
        <Fragment>
          <button
            type="button"
            className={classes}
            disabled={disabled}
            aria-label={alt}
            data-qa="button"
            data-ga={`button-${variant && variant}`}
            ref={ref}
            {...props}
            onClick={open}
          >
            {loading && <FontAwesomeIcon icon={faSpinner} className={styles.Spinner} spin />}
            {/* This wrapper element is a workaround for an old flexbug */}
            {/* https://github.com/philipwalton/flexbugs#flexbug-9 */}
            <span className={styles.ButtonInnerFlex}>{children}</span>
          </button>

          <ApplyModal
            open={showDialog}
            onDismiss={close}
            cta1href={modalUrlIsMember}
            cta1text="Yes, I'm a member"
            cta1Alt="Yes, I'm a member"
            cta2href={modalUrlNonMember}
            cta2text="No, I'd like to become a member"
            cta2Alt="No, I'd like to become a member"
          />
        </Fragment>
      )
    }

    if (isApply) {
      return (
        <Fragment>
          <button
            type="button"
            className={classes}
            disabled={disabled}
            aria-label={alt}
            data-qa="button"
            data-ga={`button-${variant && variant}`}
            ref={ref}
            {...props}
            onClick={openApply}
          >
            {loading && <FontAwesomeIcon icon={faSpinner} className={styles.Spinner} spin />}
            {/* This wrapper element is a workaround for an old flexbug */}
            {/* https://github.com/philipwalton/flexbugs#flexbug-9 */}
            <span className={styles.ButtonInnerFlex}>{children}</span>
          </button>
          <CaptureModal open={showApplyDialog} onDismiss={closeApply} cta1href={applyHref} />
        </Fragment>
      )
    }

    return (
      <Fragment>
        <button
          type="button"
          className={classes}
          disabled={disabled}
          aria-label={alt}
          data-qa="button"
          data-ga={`button-${variant && variant}`}
          ref={ref}
          {...props}
        >
          {loading && <FontAwesomeIcon icon={faSpinner} className={styles.Spinner} spin />}
          {/* This wrapper element is a workaround for an old flexbug */}
          {/* https://github.com/philipwalton/flexbugs#flexbug-9 */}
          <span className={styles.ButtonInnerFlex}>{children}</span>
        </button>

        <ApplyModal
          open={showDialog}
          onDismiss={close}
          cta1href="/"
          cta1text="Test 1"
          cta1Alt="Test 1"
          cta2href="/"
          cta2text="Test 2"
          cta2Alt="Test 2"
        />
      </Fragment>
    )
  }
)

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'light', 'lightSecondary']),
  url: PropTypes.string,
  alt: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isModal: PropTypes.bool,
  modalUrlIsMember: PropTypes.string,
  modalUrlNonMember: PropTypes.string,
  isApply: PropTypes.bool,
  applyHref: PropTypes.string,
}

Button.defaultProps = {
  variant: 'primary',
}

export { Button }
export default Button
