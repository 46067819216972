/* eslint-disable no-alert */
import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import startsWith from 'lodash/startsWith'
import noop from 'lodash/noop'
import { Link as GatsbyLink } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { ScreenReaderText } from '../../screen-reader-text'
import normalizePagePath from '../../../utilities/normalizePagePath'
import scrollIntoView from '../../../utilities/scrollIntoView'

const filetypes = {
  'application/pdf': 'PDF',
}

const UnstyledLink = forwardRef(
  (
    {
      children,
      to,
      activeClassName,
      partiallyActive,
      bypassConfirmation = false,
      state,
      replace,
      className,
      alt,
      locale,
      contentType,
      animate,
      nonGatsbySite,
      disabled,
      ...props
    },
    ref
  ) => {
    const [t, i18n] = useTranslation()
    // Assuming that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything
    // else is external.
    const internal = /^\/(?!\/)/.test(to)
    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      const internalUrl =
        locale === false
          ? to
          : normalizePagePath(to, {
              locale: locale || i18n.language || i18n.options.fallbackLng[0],
              defaultLocale: i18n.options.fallbackLng[0],
            })
      const isCrossAppBoundary =
        [/^\/loan-proposal\/mortgage-request/, /^\/loan-proposal\/mortgage-calculator/].find(
          regex => regex.test(to)
        ) !== undefined
      if (nonGatsbySite || isCrossAppBoundary) {
        return (
          <a
            href={internalUrl || '/'}
            className={className}
            aria-label={alt}
            data-qa="link"
            data-ga="link"
            ref={ref}
            {...props}
          >
            {children}
          </a>
        )
      }

      return (
        <GatsbyLink
          to={internalUrl || '/'}
          className={className}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          aria-label={alt}
          state={state}
          replace={replace}
          data-ga="link"
          data-qa="link"
          ref={ref}
          {...props}
        >
          {children}
        </GatsbyLink>
      )
    }

    const handleConfirmation = event => {
      const selection = window.confirm(
        t('UnstyledLink.thirdPartySiteConfirmation', {
          defaultValue:
            'This links to a third-party site not governed by Elevations Credit Union. Please see our third-party sites disclosure for more information',
        })
      )

      if (!selection) {
        event.preventDefault()
        return false
      }
      return true
    }

    let isRelativeHashLink = false
    let requiresConfirmation = true

    // Skip link + Sections
    if (startsWith(to, '#')) {
      requiresConfirmation = false
      isRelativeHashLink = true
    }
    // Phone numbers
    if (startsWith(to, 'tel:')) {
      requiresConfirmation = false
    }
    // Mail us links
    if (startsWith(to, 'mailto:')) {
      requiresConfirmation = false
    }
    // Any elevationscu.com (sub)domain
    if (/^https?:\/\/([a-zA-Z0-1_-]+\.)?elevationscu\.com/.test(to)) {
      requiresConfirmation = false
    }
    // Any elevationsbanking.com (sub)domain
    if (/^https?:\/\/([a-zA-Z0-1_-]+\.)?elevationsbanking\.com/.test(to)) {
      requiresConfirmation = false
    }
    // Any LoansPQ.com (sub)domain
    if (/^https?:\/\/([a-zA-Z0-1_-]+\.)?loanspq\.com/.test(to)) {
      requiresConfirmation = false
    }
    // Any IPQS cloudflare worker
    if (
      /^https?:\/\/([a-zA-Z0-1_-]+\.)?elevationscu-ipqs-page\.elevationscu\.workers\.dev\/\?application=([a-zA-Z]+)/.test(
        to
      )
    ) {
      requiresConfirmation = false
    }
    // Auto Loan for dealer purchase
    if (/^https?:\/\/([a-zA-Z0-1_-]+\.)?cudl\.com/.test(to)) {
      requiresConfirmation = false
    }
    // Contentful Static Assets bucket (only allows ECU's account assets thanks to the ID)
    if (/https:\/\/assets\.ctfassets\.net\/ekto664lcskf/.test(to)) {
      requiresConfirmation = false
    }
    // Alkami Staging
    if (/^https?:\/\/elevationscustaging\.orb\.alkamitech\.com\/([a-zA-Z0-1_-]+)/.test(to)) {
      requiresConfirmation = false
    }
    // Feedback Form
    if (/https:\/\/elevationscu\.satmetrix\.com/.test(to)) {
      requiresConfirmation = false
    }
    // Investment login links to CUSO
    if (/^https?:\/\/([a-zA-Z0-1_-]+\.)?mpv3.orcasnet\.com/.test(to)) {
      requiresConfirmation = false
    }
    // Investment login need help
    if (/^https?:\/\/([a-zA-Z0-1_-]+\.)?ola.orcasnet\.com/.test(to)) {
      requiresConfirmation = false
    }

    if (bypassConfirmation) {
      requiresConfirmation = false
    }

    let clickHandler = requiresConfirmation ? handleConfirmation : noop
    if (props.onClick) {
      if (requiresConfirmation) {
        clickHandler = event => {
          if (handleConfirmation(event)) props.onClick(event)
        }
      } else {
        clickHandler = props.onClick
      }
    }

    // Section links must contain a section ID after the hash symbol
    const isSectionLink = isRelativeHashLink && to !== '#'
    if (isSectionLink && animate) {
      clickHandler = event => {
        const nonCurrentLinks = document.querySelectorAll('a')
        event.preventDefault()
        scrollIntoView(event.currentTarget.getAttribute('href'))
        nonCurrentLinks.forEach(function (item) {
          item.setAttribute('aria-current', false)
        })
        event.currentTarget.setAttribute('aria-current', true)
      }
    }

    const target = isRelativeHashLink ? '_self' : '_blank'

    // All external links which are configured to open in a new browser window or tab
    // need to inform the user that they open in a new window. Therefore we append it
    // to the original link body below or alt (aria-label).
    let ariaLabel = alt
    if (target === '_blank' && alt && !contentType) {
      ariaLabel = `${alt} (${t('UnstyledLink.opensInNewWindow', {
        defaultValue: 'opens in a new window',
      })})`
    } else if (target === '_blank' && alt && contentType) {
      ariaLabel = `${alt} (${t('UnstyledLink.opensFileInNewWindow', {
        defaultValue: 'opens {{filetype}} in a new window',
        filetype: filetypes[contentType] || '',
      })})`
    }

    return (
      <a
        href={to}
        className={className}
        target={target}
        aria-label={ariaLabel}
        data-ga="link"
        data-qa="link"
        ref={ref}
        {...props}
        onClick={e => {
          if (disabled) {
            e.preventDefault()
          } else {
            clickHandler()
          }
        }}
      >
        {children}

        {target === '_blank' && !alt && (
          <ScreenReaderText>
            (
            {t('UnstyledLink.opensInNewWindow', {
              defaultValue: 'opens in a new window',
            })}
            )
          </ScreenReaderText>
        )}
      </a>
    )
  }
)

UnstyledLink.defaultProps = {
  animate: true,
}

UnstyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  bypassConfirmation: PropTypes.bool,
  locale: PropTypes.string,
  state: PropTypes.object,
  replace: PropTypes.bool,
  children: PropTypes.node,
  contentType: PropTypes.string,
  nonGatsbySite: PropTypes.bool,
  animate: PropTypes.bool,
  // This property should be renamed to ariaLabel at some point
  // to follow the established naming convention set forth by
  // HTML spec.
  alt: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export { UnstyledLink }
export default UnstyledLink
